import CheckWebpFeature from "./src/common/ggfx-client/module/webp-checker";
import {LocationProvider} from "./src/context/LocationContext";
import {LocaleProvider} from "./src/context/LocaleContext";

// export const wrapPageElement = ({element, props}) => {
//   const {location} = props;
//   const {locale} = element.props.pageContext;

//   return (
//     <LocationProvider location={location}>
//       <LocaleProvider locale={locale}>{element}</LocaleProvider>
//     </LocationProvider>
//   );
// };

CheckWebpFeature();

export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location.pathname)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
  if (prevLocation && prevLocation.pathname != location.pathname){
    // setTimeout(function(){
    //   if (typeof window !== "undefined") {
    //     console.log("vis_window",window)
    //     if (window?.LiveChatWidget) {
    //       console.log("vis_LiveChatWidget",window.LiveChatWidget)
    //       window.LiveChatWidget.init();// reinit chat
    //     }
    //     if (LC_API) {
    //       console.log("vis_LC_API",LC_API)
    //       LC_API.on_after_load();// hide chat
    //     }
    //   }
    // }, 3000);
  }
  if (prevLocation && (prevLocation.pathname == '/' || prevLocation.pathname == '/buy-properties/' || prevLocation.pathname == '/rent/' || prevLocation.pathname.indexOf("properties-for-sale") >= 0 || prevLocation.pathname.indexOf("properties-for-rent") >= 0)) {
    if (window)
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

  // Avoid to scroll to top when next page trigger click
  // But this feature should condiser with details page back scroll too

  if (location.pathname.match(/properties\/for-sale/)) {
    return false;
  }
  // if (location.pathname.match(/properties-for-sale/)) {
  //   return false;
  // }
  // if (location.pathname.match(/properties-for-rent/)) {
  //   return false;
  // }
  // if (location.pathname.match(/new-home-for-sale/)) {
  //   return false;
  // }
  if (location.pathname.match(/properties\/for-rent/)) {
    return false;
  }
  if (location.pathname.match(/properties\/new-homes\/for-sale/)) {
    return false;
  }


  return true;
};
